// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-single-item-js": () => import("/opt/build/repo/src/templates/single-item.js" /* webpackChunkName: "component---src-templates-single-item-js" */),
  "component---src-templates-single-tag-js": () => import("/opt/build/repo/src/templates/single-tag.js" /* webpackChunkName: "component---src-templates-single-tag-js" */),
  "component---src-templates-single-location-js": () => import("/opt/build/repo/src/templates/single-location.js" /* webpackChunkName: "component---src-templates-single-location-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("/opt/build/repo/src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-tags-js": () => import("/opt/build/repo/src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

